import React from "react";
import cx from "classnames";
import { Button } from "components/Button";
import { LinkButton } from "components/LinkButton";
import { useBetslip, useDispatch, useSelector } from "hooks";
import {
  BetslipUIStates,
  BetType,
} from "sections/Betting/Betslip/betslipSlice";
import { constructCurrency } from "common";
import { showLoginModal } from "utilities/Auth/authSlice";
import { Payout } from "sections/Betting/Betslip/components/Payout";
import { usePreferences } from "hooks/firestore/v2/user/usePreferences";
import { useProfile } from "hooks/firestore/v2/user/useProfile";
import { useFiatCurrency } from "hooks/firestore/v2/user/useFiatCurrency";

import { ReactComponent as TrashIcon } from "sections/Betting/Betslip/assets/trash.svg";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";

import * as styles from "./BetslipFooter.module.scss";

const FooterAction = () => {
  const {
    props: {
      offerQuickDeposit,
      betslipIsConfirming,
      betslipIsSubmitting,
      offerDeposit,
      canSubmitBet,
      betslipIsReviewingMessages,
      betslipIsActive,
    },
    actions: { setBetSlipState, setIsConfirming, submitBet, reuseSelection },
  } = useBetslip();
  const dispatch = useDispatch();
  const currency = useFiatCurrency();
  const { profile } = useProfile();
  const { preferences: userPreferences } = usePreferences();
  // NOTE: not using useIsLoggedin because that will need an extra render due to it being ssr safe
  // in our case we don't need it to be ssr safe since we are on client side when this is used
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  if (!isLoggedIn) {
    return (
      <Button
        onClick={() => dispatch(showLoginModal())}
        className={styles.button}
        size={`md`}
      >
        Log In To Bet
      </Button>
    );
  }

  if (betslipIsReviewingMessages) {
    return (
      <Button
        className={styles.button}
        size={`md`}
        onClick={() => dispatch(reuseSelection())}
      >
        Reuse Bets
      </Button>
    );
  }

  if (offerQuickDeposit && betslipIsActive) {
    return (
      <Button
        onClick={() =>
          dispatch(setBetSlipState(BetslipUIStates.quickDepositing))
        }
        className={styles.button}
        size={`md`}
        variant={`info`}
      >
        Quick Deposit
      </Button>
    );
  }

  if (offerDeposit && betslipIsActive) {
    return (
      <LinkButton
        to={`/wallet/deposit`}
        className={styles.button}
        size={`md`}
        variant={`info`}
        disabled={!canSubmitBet}
      >
        Deposit
      </LinkButton>
    );
  }

  if (betslipIsConfirming) {
    return (
      <Button
        variant={`warning`}
        className={styles.button}
        size={`md`}
        onClick={() =>
          dispatch(submitBet(currency, userPreferences?.acceptOdds, profile))
        }
        loading={betslipIsSubmitting}
      >
        Confirm Bets
      </Button>
    );
  }

  return (
    <Button
      onClick={() => dispatch(setIsConfirming())}
      className={styles.button}
      size={`md`}
      loading={betslipIsSubmitting}
      disabled={!canSubmitBet}
    >
      Place Bet
    </Button>
  );
};

const BetslipFooter = () => {
  const {
    props: {
      combinedMultiStake,
      combinedSinglesStake,
      betType,
      betslipIsActive,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
      payoutTotal,
    },
    actions: { clearSelections, setBetSlipState },
  } = useBetslip();
  const dispatch = useDispatch();
  const currency = useFiatCurrency();
  const isMulti = betType === BetType.Multi;
  const stake = isMulti ? combinedMultiStake : combinedSinglesStake;

  // stake/odds/payout panel should only be visible when not reviewing messages
  const shouldShowInfoPanel = !betslipIsReviewingMessages;

  return (
    <>
      <div className={styles.footer}>
        {shouldShowInfoPanel && (
          <div className={styles.info}>
            <div className={styles.panel}>
              <div className={styles.label}>Total Stake</div>
              <div className={styles.value}>
                {constructCurrency(stake, {
                  currencyType: currency,
                })}
              </div>
            </div>
            <div className={cx(styles.panel, styles.right)}>
              <div className={styles.label}>Est. Payout</div>
              <div className={styles.value}>
                <Payout amount={payoutTotal} />
              </div>
            </div>
          </div>
        )}
        <div
          className={cx(styles.buttons, {
            [styles.isReviewing]: betslipIsReviewingMessages,
          })}
        >
          {betslipIsActive ? (
            <Button
              variant={`blurred`}
              className={cx(styles.iconButton, styles.trash)}
              onClick={() => dispatch(clearSelections())}
            >
              <TrashIcon />
            </Button>
          ) : (
            <Button
              disabled={betslipIsSubmitting}
              variant={`blurred`}
              className={cx(styles.iconButton, styles.back)}
              onClick={() => dispatch(setBetSlipState(BetslipUIStates.active))}
            >
              <ChevronDown />
            </Button>
          )}

          <FooterAction />
        </div>
      </div>
    </>
  );
};

export { BetslipFooter };
