import { useEffect, useMemo, useState } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData, useDocumentData, usePermissions } from "hooks";
import { collection, query, where, doc } from "firebase/firestore";
import { createRacingEventsConverter } from "./useRace";
import type { RaceEventType } from "./RacingTypes";
import {
  type RaceMeetingType,
  createRaceMeetingsConverter,
} from "sections/Betting/Race/hooks/useRaceMeetings";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";
import type { Maybe } from "types/utilities";
import { useTokens } from "hooks/firestore/v2/user/useTokens";

export const useRaceMeeting = (
  meetingId?: string,
): [Maybe<RaceMeetingType>, Maybe<RaceEventType[]>, boolean] => {
  const db = getFirestore();
  const permissions = usePermissions();
  const [raceMeeting, setRaceMeeting] = useState<RaceMeetingType>();
  const [loading, setLoading] = useState(true);

  const { codes: userCampaigns, isLoading: isCampaignsLoading } =
    useCampaigns();
  const { tokens, isLoading: isTokensLoading } = useTokens();

  const raceMeetingsConverter = useMemo(
    () => createRaceMeetingsConverter(userCampaigns, tokens, permissions),
    [userCampaigns, tokens, permissions],
  );
  const raceEventsConverter = useMemo(
    () => createRacingEventsConverter(userCampaigns, tokens, permissions),
    [userCampaigns, tokens, permissions],
  );

  const raceMeetingsRef = useMemo(() => {
    if (!meetingId || isCampaignsLoading) return null;

    return doc(db, "raceMeetings", meetingId).withConverter(
      raceMeetingsConverter,
    );
  }, [meetingId, userCampaigns, isCampaignsLoading]);

  const racesEventsRef = useMemo(() => {
    if (!raceMeetingsRef || !meetingId) return null;

    return collection(db, "racingEvents").withConverter(raceEventsConverter);
  }, [meetingId, userCampaigns, raceMeetingsRef]);

  const compiledQuery = useMemo(() => {
    if (!raceMeetingsRef || !racesEventsRef) return null;

    return query(
      racesEventsRef,
      where("meetingId", "==", meetingId),
      where("visible", "==", true),
    );
  }, [racesEventsRef, raceMeetingsRef, userCampaigns]);

  const [raceMeetingRaw, raceMeetingLoadingRaw] =
    useDocumentData(raceMeetingsRef);
  const [races, racesLoading] = useCollectionData(
    compiledQuery,
    racesEventsRef?.path,
  );

  useEffect(() => {
    if (
      !raceMeetingRaw ||
      raceMeetingLoadingRaw ||
      racesLoading ||
      isCampaignsLoading ||
      isTokensLoading
    ) {
      setLoading(true);
      return;
    }

    setRaceMeeting(raceMeetingRaw);
    setLoading(false);
  }, [
    races,
    racesLoading,
    raceMeetingRaw,
    raceMeetingLoadingRaw,
    isCampaignsLoading,
    isTokensLoading,
  ]);

  return [raceMeeting, races?.sort((a, b) => a.number - b.number), loading];
};
