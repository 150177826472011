import React from "react";
import { motion, type Variants } from "framer-motion";
import SubItem from "./SubItem";
import type { MenuItem } from "./types";
import { ScrollArea } from "library";

import * as styles from "./CondensedMenuSubItems.module.scss";
import { useHeaderOffset, useStatusMessageOffset } from "hooks/useHeaderOffset";

type CondensedMenuSubItemsProps = {
  items: MenuItem[];
};

const variants: Variants = {
  slideOut: {
    x: "40.5rem",
    transition: { type: "spring", bounce: 0, duration: 0.2, delay: 0.2 },
  },
  rest: {
    x: 0,
    transition: { delay: 0.2 },
  },
};

const CondensedMenuSubItems = ({ items }: CondensedMenuSubItemsProps) => {
  const topOffset = useHeaderOffset(true);
  const height = `calc(100vh - ${topOffset}px)`;

  return (
    <motion.div
      initial={`rest`}
      animate={`slideOut`}
      exit={`rest`}
      className={styles.slideOut}
      variants={variants}
      style={{
        ...(topOffset && {
          top: topOffset,
          height,
        }),
      }}
    >
      <ScrollArea wheelPropagation={false}>
        <ul className={styles.items}>
          {items.map((item, index) => (
            <SubItem key={`${item.name}-${index}`} item={item} />
          ))}
        </ul>
      </ScrollArea>
    </motion.div>
  );
};

export default CondensedMenuSubItems;
