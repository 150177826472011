import React, { useEffect, useState } from "react";
import Odds from "../Odds";
import OutcomeIcon from "./OutcomeIcon";
import cx from "classnames";
import type {
  Outcome as OutcomeType,
  Competitor as CompetitorType,
} from "hooks/firestore/betting/useBetting";
import type { ToggleSelectionPayload } from "hooks/firestore/betting/types";
import { titleCase } from "title-case";
import * as styles from "./Outcome.module.scss";
import { OddsChange, OddsChangeType } from "library";
import type {
  OutcomeWithMarketType,
  OutcomesWithMarketType,
} from "../Match/MarketGroup";

type OutcomeProps = {
  id: string;
  marketId?: string;
  eventId: string;
  outcome: OutcomeType | OutcomesWithMarketType;
  competitor: CompetitorType;
  toggleSelection: (pick: ToggleSelectionPayload) => void;
  isDraw?: boolean;
  isOutright?: boolean;
  className?: string;
  align?: string;
  isSelected?: boolean;
  showOdds?: boolean;
  oddsFormat?: string;
  lightBackground?: boolean;
  isSuspended?: boolean;
  isPunted?: boolean;
  isSelectedAgainst?: boolean;
  fullWidth?: boolean;
  status: string;
  isHome: boolean;
  isAway: boolean;
};

const getOutcomeName = (outcome: OutcomeType, competitor: CompetitorType) => {
  if (outcome.abbreviation) {
    return outcome.abbreviation;
  } else if (outcome.name) {
    return outcome.name;
  } else if (competitor) {
    return competitor.name;
  } else {
    // just return something
    return titleCase(outcome.type ?? "");
  }
};

const Outcome = ({
  id,
  marketId: marketIdProp,
  eventId,
  toggleSelection,
  outcome,
  competitor,
  isDraw,
  isOutright,
  className: classNameProp,
  align,
  isSelected,
  showOdds = false,
  oddsFormat,
  lightBackground = false,
  isSuspended: isSuspendedProp,
  isPunted,
  isSelectedAgainst,
  fullWidth = false,
  status: statusProp,
  isHome = false,
  isAway = false,
}: OutcomeProps) => {
  const outcomeName = getOutcomeName(outcome as OutcomeType, competitor);
  const [isSuspended, setIsSuspended] = useState<boolean | undefined>(
    isSuspendedProp ?? false,
  );
  const [status, setStatus] = useState<string | undefined>(statusProp);
  const [marketId, setMarketId] = useState<string | undefined>(marketIdProp);

  useEffect(() => {
    if ((outcome as OutcomeWithMarketType).market) {
      const market = (outcome as OutcomeWithMarketType).market;
      setMarketId(market?.id);
      setStatus(market?.status);
      setIsSuspended(
        () =>
          market?.status === "SUSPENDED" ||
          market?.status === "SETTLED" ||
          market?.status === "DEACTIVATED" ||
          market?.status === "UNAVAILABLE" ||
          outcome.odds === 0 ||
          outcome.odds === 1 ||
          !outcome.active,
      );
    }
  }, [outcome, marketIdProp]);

  const hasClosingOdds = !!outcome?.closingOdds && status !== "ACTIVE";
  const isResulted = status === "DEACTIVATED" || status === "UNAVAILABLE";

  return (
    <OddsChange
      odds={outcome.odds as number}
      oddChangeType={OddsChangeType.sports}
      className={cx(
        styles.container,
        {
          [styles.draw]: isDraw,
          [styles.outright]: isOutright,
          [styles.fullWidthContainer]: fullWidth,
          [styles.home]: isHome,
          [styles.away]: isAway,
        },
        classNameProp,
      )}
    >
      <div
        onClick={() =>
          toggleSelection({
            type: isOutright ? "outright" : "match",
            outcomeId: id,
            marketId: marketId,
            sourceId: eventId,
            odds: outcome?.odds as number,
            hasPromotionalMarkets: outcome.market.hasPromotionalMarkets,
            eventId,
          })
        }
        className={cx(styles.outcome, {
          [styles[`${align}Align`]]: true,
          [styles.draw]: isDraw,
          [styles.above]: !isDraw,
          [styles.selected]: isSelected,
          [styles.outright]: isOutright,
          [styles.disabled]: isResulted,
          [styles.suspended]: isSuspended,
          //let user unselect a disabled outcome
          [styles.unclickable]: isSuspended && !isSelected,
          [styles.lightBackground]: lightBackground,
          [styles.punted]: isPunted,
          [styles.selectedAgainst]: !!isSelectedAgainst,
          [styles.won]: outcome.result === "WIN",
          [styles.lost]: outcome.result === "LOSE" || outcome.result === "VOID",
          [styles.fullWidthOutcome]: fullWidth,
        })}
      >
        <div
          className={cx(styles.detail, {
            [styles.fullWidthDetail]: fullWidth,
          })}
        >
          <div
            className={cx(styles.name, {
              [styles.fullWidthName]: fullWidth,
              [styles.isSelected]: isSelected,
            })}
          >
            {outcomeName}
          </div>
          {typeof outcome.odds !== "undefined" && (
            <Odds
              className={cx(styles.odds, {
                [styles.won]: outcome.result === "WIN",
                [styles.suspended]: isSuspended,
                [styles.fullWidthOdds]: fullWidth,
              })}
              base={
                hasClosingOdds
                  ? (outcome.closingOdds as number)
                  : isSuspended && !showOdds
                    ? 0
                    : (outcome.odds as number)
              }
              zero={`-`}
              format={oddsFormat}
            />
          )}
        </div>
        {!isDraw && (
          <OutcomeIcon
            outcome={outcome as OutcomeType}
            competitor={competitor}
            isSelected={isSelected}
            size={30}
            className={cx(styles.competitor, {
              [styles.fullWidthCompetitor]: fullWidth,
            })}
          />
        )}
      </div>
    </OddsChange>
  );
};

export default Outcome;
