import { useState, useEffect, useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import {
  type CampaignsType,
  type PromotionsAvailableType,
  useCollectionData,
  usePermissions,
} from "hooks";
import {
  collection,
  query,
  type FirestoreError,
  type QueryConstraint,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type DocumentData,
  type SnapshotOptions,
} from "firebase/firestore";
import {
  type RacingRouteParams,
  RegionType,
} from "sections/Betting/Racing/hooks/useRacingRoute";
import type { RaceStatuses } from "sections/Betting/Race/hooks/RacingTypes";
import {
  addDays,
  endOfDay,
  endOfToday,
  nextSaturday,
  nextSunday,
  parse,
  startOfDay,
  startOfToday,
  subDays,
  nextTuesday,
} from "date-fns";
import { shouldShowPromoBanner } from "utilities/sharedBettingUtilities";
import { parseDate } from "utilities/dateUtilities";
import type {
  RaceRegionTypes,
  RaceSportTypes,
} from "sections/Betting/Race/hooks/useRacingEvents";
import { useUserEventFilters } from "hooks/firestore/useUserAttributes";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";
import type { Maybe } from "types/utilities";
import {
  type GroupedTokens,
  useTokens,
} from "hooks/firestore/v2/user/useTokens";
import type { Event } from "hooks/firestore/v2/betting";
import type { Permissions } from "utilities/Auth/authSlice";

export type RaceMeetingType = {
  id: string;
  date: string;
  scheduledStartTime: Date;
  scheduledEndTime: Date;
  sport: RaceSportTypes;
  venue: string;
  venueId: string;
  eventCount: number;
  country: string;
  state: string;
  trackCondition: string;
  trackRating: string;
  trackType: string;
  weather: string;
  rail: string;
  region: RaceRegionTypes;
  promotionVisibility: PromotionsAvailableType;
  currentRace: {
    id: string;
    name: string;
    number: number;
    scheduledStartTime: Date;
    status: RaceStatuses;
    hasPromotionalMarkets: boolean;
    promotionCampaigns: Event["promotionCampaigns"];
    showPromoBanner: boolean;
  };
  promotionIsAvailable: boolean;
  hasPromotionalMarkets: boolean;
  promotionCampaigns: Event["promotionCampaigns"];
  showPromoBanner: boolean;
  filters: string[];
  visible: boolean;
};

export const getDateForSelectedTab = (
  tab: string,
): { start: Date; end: Date } => {
  switch (tab) {
    case "today":
    case "next-to-jump":
      return { start: startOfToday(), end: endOfToday() };
    case "tomorrow":
      return {
        start: addDays(startOfToday(), 1),
        end: addDays(endOfToday(), 1),
      };
    case "yesterday":
      return {
        start: subDays(startOfToday(), 1),
        end: subDays(endOfToday(), 1),
      };
    case "tuesday":
      return {
        start: startOfDay(nextTuesday(new Date())),
        end: endOfDay(nextTuesday(new Date())),
      };
    case "saturday":
      return {
        start: startOfDay(nextSaturday(new Date())),
        end: endOfDay(nextSaturday(new Date())),
      };
    case "sunday":
      return {
        start: startOfDay(nextSunday(new Date())),
        end: endOfDay(nextSunday(new Date())),
      };
    case undefined:
      return { start: startOfToday(), end: endOfToday() };
    default:
      // validate date as yyyy-MM-dd
      if (!parseDate(tab)) {
        return { start: startOfToday(), end: endOfToday() };
      }

      return {
        start: startOfDay(parse(tab, "yyyy-MM-dd", new Date())),
        end: endOfDay(parse(tab, "yyyy-MM-dd", new Date())),
      };
  }
};

export const getRaceMeetingsQuery = (
  route: Partial<RacingRouteParams>,
  userEventFilters: string[],
): QueryConstraint[] => {
  const queries = [where("visible", "==", true)];

  if (route?.tab) {
    const dateForSelectedTab = getDateForSelectedTab(route.tab);

    queries.push(
      where("currentRace.scheduledStartTimeTs", ">=", dateForSelectedTab.start),
    );
    queries.push(
      where("currentRace.scheduledStartTimeTs", "<=", dateForSelectedTab.end),
    );
  }

  if (userEventFilters?.length > 0) {
    queries.push(where("filters", "array-contains-any", userEventFilters));
  }

  if (route.region?.length === 1 && route.region.includes(RegionType.au)) {
    queries.push(where("region", "==", "ANZ"));
  } else if (
    route.region?.length === 1 &&
    route.region?.includes(RegionType.world)
  ) {
    queries.push(where("region", "==", "ROW"));
  }

  return queries;
};

export const createRaceMeetingsConverter = (
  userCampaigns: string[],
  userTokens: GroupedTokens,
  permissions: Permissions,
): FirestoreDataConverter<RaceMeetingType> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): RaceMeetingType => {
      const data = snapshot.data(options);

      const promotionCampaigns = data.promotionCampaigns?.filter(
        (campaign: CampaignsType) =>
          shouldShowPromoBanner(
            campaign,
            userCampaigns,
            userTokens,
            permissions,
          ),
      );

      const currentRaceCampaigns = data.currentRace.promotionCampaigns?.filter(
        (campaign: CampaignsType) =>
          shouldShowPromoBanner(
            campaign,
            userCampaigns,
            userTokens,
            permissions,
          ),
      );

      const canViewPromo = permissions["viewPromotion"] === "GRANTED";

      return {
        id: snapshot.id,
        ...data,
        currentRace: {
          ...data.currentRace,
          hasPromotionalMarkets: canViewPromo && data.hasPromotionalMarkets,
          promotionCampaigns: canViewPromo ? currentRaceCampaigns : [],
          showPromoBanner:
            canViewPromo &&
            (data.hasPromotionalMarkets || currentRaceCampaigns.length > 0),
          scheduledStartTime: parseDate(data.currentRace.scheduledStartTime),
        },
        scheduledStartTime: parseDate(data.scheduledStartTime),
        hasPromotionalMarkets: canViewPromo && data.hasPromotionalMarkets,
        promotionCampaigns: canViewPromo ? promotionCampaigns : [],
        showPromoBanner:
          canViewPromo &&
          (data.hasPromotionalMarkets || promotionCampaigns?.length > 0),
      } as RaceMeetingType;
    },
  };
};

export const useRaceMeetings = (
  route: Partial<RacingRouteParams>,
): [RaceMeetingType[], boolean, Maybe<FirestoreError>] => {
  const { codes: userCampaigns, isLoading: isCampaignsLoading } =
    useCampaigns();
  const { tokens, isLoading: isTokensLoading } = useTokens();
  const permissions = usePermissions();
  const userEventFilters = useUserEventFilters();

  const raceMeetingsConverter = useMemo(
    () => createRaceMeetingsConverter(userCampaigns, tokens, permissions),
    [userCampaigns, permissions],
  );

  const ref = useMemo(() => {
    if (isCampaignsLoading) return null;

    return collection(getFirestore(), "raceMeetings").withConverter(
      raceMeetingsConverter,
    );
  }, [userCampaigns]);

  const queries = useMemo(() => {
    if (!ref) return null;

    const extraParams = getRaceMeetingsQuery(route, userEventFilters);
    return query(ref, ...extraParams);
  }, [route, userEventFilters, userCampaigns]);

  const [raceMeetings, loading, error] = useCollectionData(queries, ref?.path);

  return [
    raceMeetings ?? [],
    loading || isCampaignsLoading || isTokensLoading,
    error,
  ];
};
