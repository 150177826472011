import React, { useRef, useEffect, type ReactNode } from "react";
import cx from "classnames";
import useWindowSize from "hooks/events/useWindowSize";
import { TabFilter, HUDPanel } from "library";
import type { IContentItem } from "types/ContentTypes";
import type { MessageType } from "library";
import { ScrollArea } from "components/ScrollArea";

import * as styles from "./HUDScrollPanel.module.scss";

type HudScrollPanelProps = {
  title: string | JSX.Element;
  count?: number;
  className?: string;
  tabs?: IContentItem[];
  contentHeader?: JSX.Element;
  contentNotifications?: ReactNode | ReactNode[];
  contentBody?: ReactNode;
  contentFooter?: ReactNode;
  messageType?: MessageType;
  onTabSelect?: (id: string) => void;
  canClose?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

const BLANK_RECT = {
  top: 0,
  bottom: 0,
  height: 0,
  width: 0,
  left: 0,
  right: 0,
};

export const HUDScrollPanel = ({
  title,
  count,
  onClose,
  className,
  canClose,
  contentHeader,
  tabs,
  onTabSelect,
  contentNotifications,
  contentBody,
  contentFooter,
  children,
}: HudScrollPanelProps) => {
  const refHeader = useRef<HTMLDivElement>();
  const refContent = useRef<HTMLDivElement>();
  const refFooter = useRef<HTMLDivElement>();
  const refNotifications = useRef<HTMLDivElement>();
  const refScrollInner = useRef<HTMLDivElement>();

  const windowSize = useWindowSize();
  useEffect(() => {
    const contentElem = refContent.current;
    if (typeof window === "undefined") {
      // give some height on mobile to prevent ui jump
      return;
    }

    if (!contentElem) {
      return;
    }

    const notificationsElement = refNotifications.current;
    const notificationsHeight =
      notificationsElement?.getBoundingClientRect()?.height || 0;

    const footerElem = refFooter.current;
    const footerHeight = footerElem?.getBoundingClientRect()?.height || 0;
    const headerRect =
      refHeader?.current?.parentElement?.parentElement?.getBoundingClientRect() || {
        ...BLANK_RECT,
      };

    const heightValue = Math.floor(
      window.innerHeight -
        headerRect.bottom -
        footerHeight -
        notificationsHeight,
    );
    contentElem.style.minHeight = heightValue + "px";
    refScrollInner.current.parentElement.style.top =
      Math.ceil(headerRect.height - 1) + "px";
  }, [windowSize]);

  const headerElems = (
    <div ref={refHeader}>
      {contentHeader}
      {tabs && tabs.length > 0 && (
        <TabFilter
          className={styles.tabs}
          onSelect={(selected) => onTabSelect && onTabSelect(selected)}
          tabs={tabs}
        />
      )}
    </div>
  );

  const bodyElems = (
    <div ref={refContent} className="hud-scroll-body">
      {children}
      {contentBody}
    </div>
  );

  const footerElems = contentFooter && (
    <div ref={refFooter} className="hud-scroll-footer">
      {contentFooter}
    </div>
  );

  return (
    <HUDPanel
      title={title}
      count={count}
      className={cx(styles.HUDScrollPanel, className)}
      onClose={onClose}
      contentHeader={headerElems}
      canClose={canClose}
    >
      <div ref={refScrollInner} className={styles.hudScrollContainer}>
        {contentNotifications ? (
          <div ref={refNotifications} className="hud-notifications">
            {contentNotifications}
          </div>
        ) : null}
        <ScrollArea className="hud-scroll-area">{bodyElems}</ScrollArea>
        {footerElems}
      </div>
    </HUDPanel>
  );
};
